import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { LayoutSingleColumn, Page, CategoryBar } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import SectionHero from './SectionHero';
import css from './LandingPage.module.css';

import { FormattedMessage } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { useConfiguration } from '../../context/configurationContext';

// const PageBuilder = loadable(() =>
//   import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
// );

export const LandingPageComponent = props => {
  const { scrollingDisabled, pageAssetsData, inProgress, error } = props;
  const config = useConfiguration();


  return (
    // <PageBuilder
    //   pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
    //   inProgress={inProgress}
    //   error={error}
    //   fallbackPage={<FallbackPage error={error} />}
    // />
    <Page
      title={'HomePage'}
      scrollingDisabled={scrollingDisabled}
      referrer="origin"
    >
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer />}
        footer={<FooterContainer />}
      >

        <CategoryBar />
        <SectionHero config={config} />

      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};

  return { pageAssetsData, inProgress, error,  scrollingDisabled: isScrollingDisabled(state), };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
